import React, { FC, useMemo, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import ProductsCarousel from 'components/ProductsCarousel';
import ProductCard from 'components/ProductCard';
import { IResponsiveObj } from 'components/ControlledCarousel/model';
import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { gtmService } from 'services/gtmService';

import responsiveObj from './constants';
import { IPropsFeaturedProducts } from './model';

import './FeaturedProducts.scss';

const FeaturedProducts: FC<IPropsFeaturedProducts> = ({
  products,
  title,
  subtitle,
  sectionBtn,
  cardBtnData,
  bgColor,
  enableProductsCTA,
  responsiveCarouselStructure,
  carouselControls,
  featuredProductsAnchor,
  listingName,
}) => {
  const bgColorApplied = bgColor?.[0]?.properties.colorPicker?.label
    ? `${bgColor[0].properties.colorPicker.label}-default-bg`
    : 'gallery';
  const blockClasses = classNames('featured-products', {
    [bgColorApplied]: bgColorApplied,
  });

  const isShowProductCTA = enableProductsCTA === '1';

  const responsiveObjCustom: IResponsiveObj | null = useMemo(() => {
    if (responsiveCarouselStructure && responsiveCarouselStructure[0]) {
      const result = { ...responsiveObj };
      const data = responsiveCarouselStructure[0].properties;
      Object.keys(data).forEach((item: string) => {
        if (result?.[item] && data[item][0]?.properties) {
          const { items, partialVisibilityGutter } = data[item][0].properties;
          if (Number.isInteger(items) && items > -1) {
            result[item].items = items;
          }
          if (Number.isInteger(partialVisibilityGutter) && partialVisibilityGutter > -1) {
            result[item].partialVisibilityGutter = partialVisibilityGutter;
          }
        }
      });

      return result;
    }

    return null;
  }, [responsiveCarouselStructure]);

  useEffect(() => {
    const timeoutId = gtmService.emitProductListingView(listingName, products);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  return products && products.length ? (
    <div
      className="campaign-anchor-section"
      {...(featuredProductsAnchor ? { id: featuredProductsAnchor } : {})}
    >
      <div data-test="FeaturedProducts" className={blockClasses}>
        <Container fluid>
          <ProductsCarousel
            title={title || ''}
            subtitle={subtitle || ''}
            responsiveObj={responsiveObjCustom || responsiveObj}
            btn={sectionBtn}
            carouselClass="featured-products"
            bgColor={bgColor?.[0]?.properties.colorPicker?.label || undefined}
            {...carouselControls}
          >
            {products.map(
              ({
                sku,
                preferred,
                productLinks,
                productFamily,
                link,
                productImageAlt,
                pageName,
                productRelatedImage,
                seoMetaDescription,
                defaultProductImage,
                productVariant,
              }) => (
                <ProductCard
                  key={sku}
                  label={preferred?.[0]?.title}
                  labelBgColor={
                    productFamily?.[0]?.productListingBadgeBgColor?.[0]?.properties?.colorPicker
                      ?.label || DEFAULT_PRODUCT_FAMILY_COLOR
                  }
                  title={pageName || ''}
                  text={seoMetaDescription}
                  img={productRelatedImage || defaultProductImage}
                  classes="featured-products-product-card"
                  btn={{
                    shopBtnAriaLabel: productLinks?.[0]?.properties?.shopBtnAriaLabel,
                    link: [
                      {
                        url: productLinks?.[0]?.properties?.link?.[0]?.url,
                        name: productLinks?.[0]?.properties?.link?.[0]?.name,
                      },
                    ],
                    btnStyles: cardBtnData?.btnStyles,
                    icon: productLinks?.[0]?.properties?.icon,
                    shopName: productLinks?.[0]?.properties?.shopName,
                  }}
                  shopBtnShow={isShowProductCTA}
                  link={link}
                  fontColor="navy"
                  alt={productImageAlt}
                  sku={sku}
                  preferred={preferred}
                  productVariant={productVariant}
                />
              )
            )}
          </ProductsCarousel>
        </Container>
      </div>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentFeaturedProducts on IFeaturedProducts {
    featuredProductsBgColor {
      ...FragmentColorProps
    }
    featuredProductsCTASectionButtonData {
      properties {
        ...FragmentNFButton
      }
    }
    featuredProductsCTACardButtonData {
      properties {
        ...FragmentNFButton
      }
    }
    featuredProductsSectionSubtitle
    featuredProductsSectionTitle
    featuredProductsEnableProductsCTA
    featuredProductsEnableProductsSecondaryText
    featuredProductsResponsiveCarouselStructure {
      properties {
        ...FragmentResponsiveCarousel
      }
    }
    featuredProductsCTACardButtonIcon {
      fallbackUrl
      svg {
        content
      }
    }
    featuredProductsAnchor
  }
`;

export default FeaturedProducts;
